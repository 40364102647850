<template>
  <div >
    <HeaderView :page_index="-1" />
    <el-skeleton v-if="loading" :rows="5" animated  style="text-align: left;" />
    <div v-else class="home">
      <el-container>
      <el-container>
        <el-aside width="200px">

        </el-aside>
        <el-container>
          <el-skeleton v-if="loading" :rows="5" animated  style="text-align: left;" />
          <el-main  v-else style="text-align: left;">
            <div class="head_view">
              <div class="title"> {{info.title}}</div>
              <div class="desc"> {{info.desc}}</div>
            </div>
            <div v-for="(bind_info, index) in info.bind_list" :key="index" class="one_line" style=" margin: 6px; vertical-align: top; ">
              <div class="row line1">{{ index + 1 }}</div>
              <div class="row line2"><OnePersonCanPull :person_info="bind_info.person_info" tag="only"/></div>
              <div class="row line3">{{ bind_info.person_info.desc }}</div>
              <div class="row line4">{{ bind_info.desc }}</div>
            </div>
          </el-main>
        </el-container>
      </el-container>
    </el-container>
  </div>
</div>
</template>

<script>
import store from '@/store'
import Utils from '@/utils/utils'
import axios from 'axios'
import OnePersonCanPull from '@/components/items/OnePersonCanPull'
import HeaderView from '@/components/other/HeaderView'

export default {
  name: 'Rank_detail',
  mounted () {
    this.get_index()
  },
  components: {
    HeaderView, OnePersonCanPull
  },
  data () {
    return {
      loading: true,
      loading2: false,
      loading3: false,
      is_long: false,
      niming: store.state.niming,
      base_img: store.state.base_img,
      type_list: [],
      pre_list: [],
      // in_wheres: [['不介意', null], ['结巴', 'j'], ['pku', 'p'], ['上版本', '1'], ['都介意', 'all']],
      see_wants: [['全部', 'all'], ['type', 'type'], ['my', 'my'], ['net', 'net'], ['都不', 'none']],
      current_type_index: 0,
      current_group_index: 0,
      current_page: 1,
      zici_paixu: 'index',
      group_tag: 'my',
      person_selects: [],
      see_want: 'net',
      content2: ''
    }
  },
  methods: {
    get_index () {
      if (localStorage.getItem('search_person_paixu')) {
        this.person_paixu = localStorage.getItem('person_paixu')
      }
      axios.post(Utils.check_is_niming() ? '/rank_detail_unlogin/' : '/rank_detail/', { id: this.$route.params.id })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert(res.data.msg)
            return
          }
          this.info = res.data.data.info
          this.loading = false
        })
    },
    search_words (e) {
      this.loading3 = true
      this.list = []
      this.current_page = 1

      axios.post('/rank_person_search/', { rank_id: this.info.id, content: this.content2, paixu: this.person_paixu })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert(res.data.msg)
            return
          }
          this.loading3 = false
          this.pre_list = res.data.data.list
          this.group_list = res.data.data.group_list
          // this.content = ''
          // Utils.alert_success('添加成功')
        })
    },
    add_select_persons (e) {
      console.log(this.person_selects)
    },
    refresh_quality_tab_list () {},
    set_paixu (e) {
      localStorage.setItem('search_person_paixu', e)
      this.person_paixu = e
      this.refresh_quality_tab_list()
    },
    change_is_rank (e) {
      console.log(e)
    },
    rank_add_select_persons () {
      this.loading3 = true
      var persons = []
      for (var i = 0; i < this.pre_list.length; i++) {
        var bindInfo = this.pre_list[i]
        if (bindInfo.select) {
          persons.push({ person_id: bindInfo.info.id, index: bindInfo.index, desc: bindInfo.desc })
        }
      }
      axios.post('/rank_add_select_persons/', { rank_id: this.info.id, persons: persons })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert(res.data.msg)
            return
          }
          this.info = res.data.data.info
          this.loading3 = false
          Utils.alert_success('添加成功')
        })
    }
  }
}
</script>
<style scoped>
.tab2{
  display: block;
  text-align: left;
  padding: 30px 40px 30px 20px;
}
.tab2 .el-tag,.tab2  .el-dropdown{
  vertical-align: middle;
}
.to_sw{
  color: #fff;
  background: #67c23a;
  padding: 16px 0px;
  width: 160px;
  margin: 20px 0px 20px 40px;
  cursor:pointer;
}

.mx-1{
  margin: 0px 5px;
  cursor:pointer;
}
ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}
li{
  background: lightblue;
}
li div.border_bottom{
  border-bottom:  1px solid #999;
}
li div.border_top{
  border-top:  1px solid #999;
}
li div.border_right{
  border-right:  1px solid #999;
}
li div.border_left{
  border-left:  1px solid #999;
}
li div{
  /* vertical-align: middle; */
  /* display:table-cell; */
  /* border: 1px solid pink; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor:pointer;

}
li div.left,li div.right{
  display: inline-block;
  width: 98px;
  vertical-align: top;
}

li .heng2{
  width: 200px;
}
.head_view{
  margin: 30px 0px;
}
.head_view .title{
  color: #50b7c1;
  font-size: 32px;
  font-weight: bold;
}
.head_view .desc{
  margin: 10px 100px 10px 0px;
  font-size: 16px;
}
.one_line .row{
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
}
.line1{
  width: 30px;
  text-align: left;
  color: #aaa;

}
.line2{
  width: 100px;
  text-align: center;

}
.line3{
  width: 250px;
  text-align: left;
  color: #999;
  overflow: hidden;
  white-space:nowrap
}
.line4{
  margin-left: 20px;
  width: 300px;
  text-align: left;
}
</style>
